import React from "react";
import Name from "../components/Name";

const Footer = () => (
  <footer>
    <div className="py-8 px-5">
      <Name />
    </div>

    <div className="font-mono text-sm flex justify-around flex-wrap bg-gray-100 px-3 py-10">
      <p>site made from scratch</p>
      {/* <p>
        {" "}
        <span role="img" aria-label="globe emoji">
          🌐
        </span>{" "}
      </p> */}
      <p>© 2006-{new Date().getFullYear()} Nathaniel Noyd</p>
    </div>
    {/* <div className="font-mono text-sm flex justify-around flex-wrap bg-gray-100 px-3 py-10">
      <p>
        {" "}
        <span role="img" aria-label="globe emoji">
          🌐
        </span>{" "}
      </p>
    </div> */}
  </footer>
);
export default Footer;
